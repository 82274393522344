import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { Observable, tap } from "rxjs";
import { IAssociation } from "../interfaces";
import { IResponse, IResponseDTO } from "../interfaces/response.interface";

@Injectable({ providedIn: "root" })
export class AssociationService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getAssociationsByManager(managerId = "") {
    return this.http.get<IResponse<IAssociation[]>>(
      `${this.apiUrl}/associations`,
      {
        withCredentials: true,
        headers: {
          manager: managerId,
        },
      }
    );
  }

  searchAssociations(params: any) {
    return this.http.post<IResponseDTO<IAssociation[]>>(
      `${environment.apiUrl}/properties/search`,
      {
        ...params,
        withCredentials: true,
      }
    );
  }

  getAllAssocations() {
    type Resp = { properties?: IAssociation[] };
    return this.http.get<Resp>(`${this.apiUrl}/associations`, {
      withCredentials: true,
      // headers: {
      //   manager,
      // },
    });
  }

  public async getAssociations(
    manager: string | undefined = "",
    selectAll: boolean = false
  ) {
    this.http
      .get<IResponse<IAssociation[]>>(`${this.apiUrl}/associations`, {
        withCredentials: true,
        headers: {
          manager,
        },
      })
      .pipe(
        tap(data => {
          let optionsAll: any;
          //add for all data if manager selected
          if (selectAll) {
            // this is for selec all options
            optionsAll = {
              id: "123",
              name: "Select All",
              slug: "annslee-lakes-30052",
              managerName: "Elisa Miller",
              city: "Loganville",
              zip: "30052",
              latitude: "33.79817414976809",
              longitude: "-83.87988924881662",
              statusName: "Unknown",
              units_count: "105",
            };
            // this._associations$.next([optionsAll, ...data["properties"]]);
            // } else {
            // this._associations$.next(data["properties"]);
          }
        })
      )
      .subscribe();
  }

  public getAssociation = (id: string): Observable<IAssociation> => {
    return this.http.get<any>(`${this.apiUrl}/associations/${id}`, {
      withCredentials: true,
    });
  };

  getAssociationBoardMembers = <Resp = any>(id: string) => {
    return this.http.get<Resp>(
      `${this.apiUrl}/associations/${id}/board`,
      {
        withCredentials: true,
      }
    );
  };

  public getAssociationOptions = (
    id: string,
    options: any
  ): Observable<any> => {
    return this.http.post<any>(
      `${this.apiUrl}/associations/${id}/options`,
      options,
      {
        withCredentials: true,
      }
    );
  };

  public getAssociationBySlug = (slug: string | null): Observable<any> => {
    return this.http.get<any>(`${this.apiUrl}/association-slug/${slug}`, {
      withCredentials: true,
    });
  };
  public getAssociationUnitsBySlug = (slug: string | null): Observable<any> => {
    return this.http.get<any>(`${this.apiUrl}/association-slug/${slug}/units`, {
      withCredentials: true,
    });
  };

  public getUnitBySlug = (slug: string | null): Observable<any> => {
    return this.http.get<any>(`${this.apiUrl}/units/${slug}`, {
      withCredentials: true,
    });
  };

  public editAssociationGeneralInfo(
    associationId: string,
    info: any
  ): Observable<any> {
    return this.http.patch<any>(
      `${this.apiUrl}/associations/${associationId}`,
      info,
      { withCredentials: true }
    );
  }

  public saveAssociationGeneralInfo(info: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/associations`, info, {
      withCredentials: true,
    });
  }

  public saveAssociationOptions(
    associationId: string,
    options: any
  ): Observable<any> {
    return this.http.patch<any>(
      `${this.apiUrl}/associations/${associationId}/options`,
      options,
      { withCredentials: true }
    );
  }
  public saveAssociationServices(
    associationId: string,
    amenities: any
  ): Observable<any> {
    return this.http.patch<any>(
      `${this.apiUrl}/associations/${associationId}/services`,
      amenities,
      { withCredentials: true }
    );
  }

  public getAccountsItemsMethodsTerms(associationId: string): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}/associations/${associationId}/accounts-items-methods-terms`,
      { withCredentials: true }
    );
  }

  public getAssociationList = (): Observable<any> => {
    const url = `${this.apiUrl}/associations`;
    return this.http.get<any[]>(url, { withCredentials: true });
  };

  public getUnitsByAssociationId = (id: string): Observable<any> => {
    const url = `${this.apiUrl}/associations/${id}/units`;
    return this.http.get<any>(url, { withCredentials: true });
  };

  //Association General [tab property types]
  public getListOfUnitTypes = (associationId: string): Observable<any> => {
    const url = `${this.apiUrl}/unit-types`;
    return this.http.get<any>(url, { withCredentials: true });
  };

  public getListOfUnitTypesById = (associationId: string): Observable<any> => {
    const url = `${this.apiUrl}/properties/${associationId}/unit-types`;
    return this.http.get<any>(url, { withCredentials: true });
  };

  public editAssociationsPropertyTypes = (
    associationId: string,
    data: any[]
  ): Observable<any> => {
    const url = `${this.apiUrl}/properties/${associationId}/unit-types`;
    return this.http.patch<any>(url, data, { withCredentials: true });
  };

  public saveAssociationsPropertyTypes = (data: any): Observable<any> => {
    const url = `${this.apiUrl}/properties/unit-types`;
    return this.http.post<any>(url, data, { withCredentials: true });
  };

  //Association General [tab address]
  public getListofAddress = (associationId: string): Observable<any> => {
    const url = `${this.apiUrl}/properties/${associationId}/addresses`;
    return this.http.get<any>(url, { withCredentials: true });
  };

  public saveAssociationsAddress = (data: any): Observable<any> => {
    const url = `${this.apiUrl}/addresses`;
    return this.http.post<any>(url, data, { withCredentials: true });
  };

  public editAssociationsAddress = (
    associationId: string,
    data: any
  ): Observable<any> => {
    const url = `${this.apiUrl}/properties/${associationId}/addresses`;
    return this.http.patch<any>(url, data, { withCredentials: true });
  };
}
