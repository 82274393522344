import { HttpBackend, HttpClient } from "@angular/common/http";
import { inject, Injectable, isDevMode } from "@angular/core";
import { getToken, Messaging, onMessage } from "@angular/fire/messaging";
import { environment } from "@environments/environment";
import { lastValueFrom } from "rxjs";


/**
 * State management to manage Push Notification
 */
@Injectable({ providedIn: "root" })
export class PushNotificationStore {
  userAuthToken: string = '';
  readonly #http: HttpClient;
  readonly #env = environment;
  readonly #messaging = inject(Messaging);
  #serviceWorker: ServiceWorkerRegistration | undefined;

  constructor(httpBackend: HttpBackend) {
    this.#http = new HttpClient(httpBackend);
  }

  /**
   * installation, most likely it's can be used when user login
   * 
   */
  async install(options: { accessToken: string }) {
    this.userAuthToken = options.accessToken;

    this.track('log', "installing...");

    const is_allowed = await this.requestNotificationPermision();
    if (!is_allowed) {
      this.track('log', "canceled");
      return;
    }

    await this.registration();

    this.getServiceWorkerNode().then(() => {
      this.prepareServiceWorker();
    });

    await this.listening();

    this.track('log', "installed");
  }

  /**
   * Prepare to get service worker object to interact with
   * 
   */
  private async getServiceWorkerNode() {
    const is_navigator_avail = 'serviceWorker' in navigator;

    if (!is_navigator_avail) {
      throw new Error("can't can;t find navigator on this context");
    }
    this.track('log', "navigator reached");

    this.track('log', "get service worker object");
    const swRegistration = await navigator.serviceWorker.getRegistrations();

    const fcmServiceWorker = swRegistration.find((sw) => {
      return sw.active?.scriptURL.includes("firebase-messaging-sw.js")
    });

    if (!fcmServiceWorker) {
      this.track('log', "service worker not found, so skip it");
      return;
    }

    this.#serviceWorker = fcmServiceWorker;
  }

  /**
   * initial service worker to listen in background
   */
  private async prepareServiceWorker() {
    if (!this.#serviceWorker) {
      throw new Error("service worker not ready yet when try to prepare it")
    }

    this.#serviceWorker.active?.postMessage({
      action: 'initial',
      params: {
        config: {
          projectId: this.#env.firebase.projectId,
          appId: this.#env.firebase.appId,
          apiKey: this.#env.firebase.apiKey,
          authDomain: this.#env.firebase.authDomain,
          messagingSenderId: this.#env.firebase.messaging.messagingSenderId,
        }
      }
    });
  }


  /**
   * unregister FCM Service Worker
   */
  private async unregisterServiceWorker() {
    if (!this.#serviceWorker) {
      this.track('warn', "service worker not found");
      return;
    }

    this.track('log', "ok it's unregistered");
    this.#serviceWorker.unregister();
  }

  /**
   * uninstall, most likely it's can be used when user logout
   * 
   * when uninstalled, 
   * notification will not be pushed 
   * when user not focus on window / close the window
   * 
   */
  async uninstall() {
    this.track('log', "uninstalling...");

    await this.unregisterServiceWorker()

    this.track('log', "uninstalled");
  }

  get #isTrackable() {
    return isDevMode();
  }

  /**
   * Tracks messages based on the console method passed.
   * Supports 'log', 'warn', 'error', 'info', etc.
   * 
   * Example usage: this.track('log', 'message', { data: true });
   */
  private track(method: keyof Pick<Console, 'log' | 'error' | 'warn' | 'info'>, ...args: any[]) {
    if (!this.#isTrackable) return;

    args.unshift(this.constructor.name);

    const consoleMethod = console[method] || console.log;
    (consoleMethod as any).apply(console, args);
  }

  /**
   * check notification permission
   * 
   * @returns 
   */
  private async requestNotificationPermision() {
    // Check if the Notification API is supported by the browser
    if (!("Notification" in window)) {
      this.track('log',"This browser does not support notifications.");
      return;
    }

    if (Notification.permission === "granted") {
      this.track('log', "Notification permission has already been granted.");
      return true;
    }
    
    if (Notification.permission === "denied") {
      this.track('warn', "Notification permission has been denied.");
      return false;
    }

    const is_allowed = await Notification.requestPermission().then((permission) => {
      if (permission !== "denied") {
        this.track('log', "Notification permission was not granted.");
        return false;
      }
      
      this.track('log', "Notification permission granted.");

      new Notification("Thank you for enabling notifications!", {
        icon: '/assets/svg/sidenav/roam-mobile-logo.svg',
      });

      return true;
    });

    return is_allowed;
  }

  /**
   * get device token that can be share to backend as device flag
   * 
   * @returns unique token
   */
  private async getDeviceToken() {
    const token = await getToken(this.#messaging, {
      vapidKey: this.#env.firebase.messaging.vapidKey
    });

    this.track('log', "token: " + token);

    return token;
  }

  private async registration() {
    this.track('log', "registrating...");

    const userToken = this.userAuthToken;

    if (!userToken) {
      throw Error("can't be process because there's no user token")
    }

    const token = await this.getDeviceToken();

    const request = this.#http.post(
      `${environment.apiUrl}/pushnotification/registration`,
      { token },
      {
        headers: {
          Authorization: 'Bearer ' + userToken
        }
      }
    )

    let result = false;
    request.subscribe({
      error: (err) => {
        this.track('error', "registration failed", err);
        throw Error("can't ")
      },
      complete: () => {
        this.track('log', "registrated");
        result = true;
        return true;
      }
    });

    await lastValueFrom(request);

    return result
  }

  private async listening() {
    onMessage(this.#messaging, (payload) => {
      this.track('log', "new pushnotification payload", payload);

      if (payload.notification) {
        new Notification(payload.notification?.title ?? 'Roamstay Notification', {
          body: payload.notification?.body ?? '',
          icon: payload.notification.image ?? '/assets/svg/sidenav/roam-mobile-logo.svg'
        });
      }
    });

    this.track('log', "ready to listen");
  }
}